<script>
import axios from "axios";
import {VALIDATOR_DELEGATIONS} from "../../../routes/routes"

export default {
    name: "ValidatorDelegationsTable",
    data() {
        return {
            validatorDelegationsPage: 1,
            delegators: [],
            showMoreButton: true,
        }
    },
    props: {
        validator: {
            type: String,
            default: ''
        }
    },
    created() {
        this.fetchValidatorDelegations()
    },
    methods: {
        fetchValidatorDelegations() {

            let params = this.validator + '/paginate/10/' + this.validatorDelegationsPage

            axios({
                method: "get",
                url: VALIDATOR_DELEGATIONS +
                    params,
                timeout: 1000 * 20,
            }).then(response => {
                if (!response.data.length) {
                    this.showMoreButton = false
                    return;
                }
                response.data.forEach((elem) => {
                    this.delegators.push({
                        address: elem.address,
                        amount: Number(elem.amount[0].amount + 'e-6')
                    })
                })
                if (response.data.length < 10) {
                    this.showMoreButton = false
                }
            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
                this.showMoreButton = false
            });
        },
        loadMore() {
            this.validatorDelegationsPage++;
            this.fetchValidatorDelegations()
        }
    }
}
</script>


<template>
    <div>
        <div  class="row">
            <div class="info-table delegators">
                <table>
                    <thead>
                    <tr>
                        <td>{{ $t('table.Delegator') }}</td>
                        <td class="text-right">{{ $t('table.Delegation') }}</td>
                        <!--                <td class="text-right">{{ $t('table.Commission') }}</td>-->
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(delegator,index) in delegators" :key="index" class="table-row">
                        <td class="blue-linear">
                            <router-link :to="{ name: 'Address', params: { id: delegator.address }}">
                                {{ delegator.address }}
                            </router-link>
                        </td>
                        <td v-if="Number(delegator.amount) < 0.01" class="default text-center">&lt; 0.01</td>
                        <td v-else class="default text-right">{{ delegator.amount.toLocaleString() }}</td>
                        <!--                <td class="default text-right">{{delegator.delegationFee }}%</td>-->
                    </tr>
                    </tbody>
                </table>

            </div>
        </div>
        <div v-if="showMoreButton" @click="loadMore()" class="grey-button" style="margin: 16px auto">
            <span class="blue-text">{{ $t('message.showMore') }}</span>
        </div>
    </div>


</template>

<style scoped>

</style>