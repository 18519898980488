import { createStore } from 'vuex'

export default createStore({
    state: {
        authorizedUser:{},
    },
    mutations: {
        UPDATE_AUTHORIZED(state, payload) {
            state.authorizedUser = payload
        }
    },
    actions: {
        setAuthorized(context, payload) {
            context.commit('UPDATE_AUTHORIZED', payload)
        }
    },
    getters: {
        authorizedUser: function (state) {
            return state.authorizedUser
        }
    }
})