<template>
    <div>
        <template v-if="block">
            <div class="page-title">
                <div class="backButton" @click="goBack"></div>
                {{ $t('message.blockDetails') }}
            </div>
            <block-info :block="block"></block-info>

            <div class="info-table-block">
                <div class="table-selector">
                    <div class="table-selector-item" :class="active =='transactions' ? 'active' : ''"
                         @click="active ='transactions'">{{ $t('table.Transactions') }}
                    </div>
                    <div class="table-selector-item" :class="active =='events' ? 'active' : ''"
                         @click="active ='events'">{{ $t('table.Events') }}
                    </div>
                </div>
                <div class="row" :class="active==='transactions' ? 'row-overflow-y' : ''">
                    <div v-if="active=='transactions'" class="info-table">
                        <div v-if="!showTransactions.length" class="not-found">
                            {{ $t('toasts.nothingFound') }}
                        </div>
                        <transactions-table v-if="showTransactions.length" :transactions="showTransactions" :highlightBlockNumber="false"></transactions-table>
                    </div>
                    <div v-if="active=='events'" class="info-table">
                        <!--                    <events-table :events="evts.data"></events-table>-->
                        <div v-if="!events.length" class="not-found">
                            {{ $t('toasts.nothingFound') }}
                        </div>
                        <div v-else v-for="(item, index) in events" :key="index">
                            <div class="title">[{{index}}] {{item.type}}</div>
                            <div class="grey-block">
                                <json-viewer :value="item.attributes" :expand-depth="2" theme="my-awesome-json-theme"></json-viewer>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-nav" v-if="active=='transactions'">
                    <div class="show-all grey-button button" style="visibility: hidden; display: none">
                        {{ $t('message.showAll') }}
                    </div>
                    <explorer-paginator v-if="showTransactions.length" :current-page="transactions.current_page" :last-page="transactions.last_page"
                                        @pagePlusArgument="pagePlusArgument" @prevPage="prevPage"
                                        @nextPage="nextPage" @goLastPage="goLastPage"/>
                    <show-lines-count v-if="showTransactions.length" @count="setPageCount"></show-lines-count>
                </div>
            </div>
        </template>
        <div v-else class="page-not-found">
            <div class="page-not-found-title">404</div>
            <div class="page-title">
                {{ $t('errors.block_not_found') }}
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {BLOCKS, VALIDATORS} from "../../../routes/routes"
import ExplorerPaginator from "@/components/Tools/ExplorerPaginator";
import BlockInfo from "@/components/Blocks/BlockInfo";
import TransactionsTable from "@/components/Transactions/TransactionsTable";
import ShowLinesCount from "@/components/Filters/ShowLinesCount";

export default {
    name: "OneBlock",
    components: {ShowLinesCount, TransactionsTable, BlockInfo, ExplorerPaginator},
    data() {
        return {
            currentBlock: 1,
            active: 'transactions',
            showTransactions: [],
            transactions: {
                current_page: 1,
                data: [],
                last_page: 0
            },
            transactionsOnPage: 10,
            events: {},
            evts: {
                current_page: 1,
                data: [],
                last_page: 0
            },
            block: {},
        }
    },
    created() {
        this.currentBlock = this.$router.currentRoute._value.params.id;
        this.fetchBlock()
    },
    mounted() {},
    methods: {
        fetchProposer() {
            axios({
                method: "get",
                url: VALIDATORS,
                timeout: 1000 * 20,
            }).then(response => {
                let item = response.data.find((elem) => {
                    return (elem.consensusAddress === this.block.proposerAddress)
                })

                if(item){
                    this.block.proposer = {
                        name: item.name,
                        address: item.bech32Address,
                        icon: item.logoUrl,
                    }
                }
            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        },
        fetchBlock(){
            axios({
                method: "get",
                url: BLOCKS + this.currentBlock,
                timeout: 1000 * 20,
            }).then(response => {
                let info = response.data
                this.block = {
                    number: info._id,
                    hash: info.hash,
                    height: info._id,
                    chainID: info.chainId,
                    proposerAddress: info.proposer,
                    proposer: {name: '', icon: ''},
                    blockTime: this.$dayjs(info.timestamp).unix(),
                    TxCount: info.txCnt,
                    EvCount: info.evtCnt,
                }
                this.fetchProposer()
                this.collectTransactionsList(info)

                this.events = info.evts
                this.events?.forEach((event) => {
                    this.evts.data.push({
                        height: event.blockId,
                        message: event.type,
                        txTime: this.$dayjs(event.timestamp).unix(),
                        related: event.related,
                        amount: event.attributes?.find(el => el.key==="amount" || el.key==="ucoins")?.value || 0
                    })
                })

                console.log(this.events)
            }).catch(error => {
                if (error.code === 'ERR_BAD_REQUEST') {
                    this.block = null;
                }
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        },
        collectTransactionsList(info){
            info.txs?.forEach((transaction) => {
                this.transactions.data.push({
                    messages: transaction.type,
                    hash: transaction._id,
                    height: transaction.blockId,
                    status: transaction.code,
                    txTime: this.$dayjs(transaction.timestamp).unix(),
                })
            })
            this.transactions.last_page = Math.ceil(this.transactions.data.length/this.transactionsOnPage)
            this.showTransactions = this.transactions.data.slice(0, this.transactionsOnPage)
            this.transactions.current_page = 1
        },

        cutTransactionsForPage(){
            this.showTransactions=[]
            this.transactions.last_page = Math.ceil(this.transactions.data.length/this.transactionsOnPage)
            this.showTransactions = this.transactions.data.slice((this.transactions.current_page-1)*this.transactionsOnPage, this.transactions.current_page*this.transactionsOnPage)
        },

        setPageCount(e) {
            this.transactionsOnPage = e
            this.transactions.current_page = 1
            this.cutTransactionsForPage()
        },
        prevPage() {
            if(this.transactions.current_page > 1){
                this.transactions.current_page = this.transactions.current_page - 1
                this.cutTransactionsForPage()
            }
        },
        nextPage() {
            if(this.transactions.current_page < this.transactions.last_page) {
                this.transactions.current_page = this.transactions.current_page + 1
                this.cutTransactionsForPage()
            }
        },
        goLastPage() {
            this.transactions.current_page = this.transactions.last_page
            this.cutTransactionsForPage()
        },
        pagePlusArgument(e) {
            this.transactions.current_page = this.transactions.current_page + e
            this.cutTransactionsForPage()
        },
    }
}
</script>

<style scoped>

</style>