<template>
    <div>
        <h2 class="chain-data-title">
            {{ $t('message.chainData') }}
        </h2>

        <div class="statistic block-statistic">
            <div class="statistic-block">
                <div class="title">
                    {{ $t('table.Mined') }}
                </div>
                <div class="value">
                    {{statistic.mined.current.toLocaleString()}} <span class="currency">AXM</span>
                </div>
                <div class="sub-title">
                    {{(statistic.mined.current/statistic.mined.total*100).toFixed(2)}}% {{ $t('table.fromTotal') }}
                </div>
            </div>

            <div class="statistic-block">
                <div class="title">
                    {{ $t('table.TransactionsCount') }}
                </div>
                <div class="value">
                    {{statistic.transactions.count.toLocaleString()}}
                </div>
                <div class="sub-title">
                    {{statistic.transactions.perSecond}}  {{ $t('table.TransSec') }}
                </div>
            </div>

            <div class="statistic-block">
                <div class="title">
                    {{ $t('table.adresses_created') }}
                </div>
                <div class="value">
                    {{statistic.registrations.toLocaleString()}}
                </div>
                <div class="sub-title">
                    +{{statistic.registeredToday.toLocaleString()}}  {{ $t('table.registeredToday') }}
                </div>
            </div>

            <div class="statistic-block">
                <div class="title">
                    {{ $t('table.TotalLocked') }}
                </div>
                <div class="value">
                    {{statistic.locked.toLocaleString()}} <span class="currency">AXM</span>
                </div>
                <div class="sub-title">
                    {{(statistic.locked/statistic.mined.current*100).toFixed(3)|| '0.00'}}% {{ $t('table.fromMined') }}
                </div>
            </div>

            <div class="statistic-block">
                <div class="title">
                    {{ $t('table.EventsCount') }}
                </div>
                <div class="value">
                    {{statistic.burned.toLocaleString()}} <span class="currency">AXM</span>
                </div>
                <div class="sub-title">
                    {{statistic.burnedToday.toLocaleString()}}  {{ $t('table.registeredToday') }}
                </div>
            </div>

            <div class="statistic-block">
                <div class="title">
                    {{ $t('table.BlockTime') }}
                </div>
                <div class="value">
                    ≈ {{statistic.blockTime.toFixed(2) || 0}} <span class="currency">{{ $t('table.sec') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import {CHAIN_STATISTIC_CACHE} from "../../../routes/routes"

    export default {
        name: "ChainData",
        data() {
            return {
                statistic: {
                    mined:{
                        current: 0,
                        total: 0,
                    },
                    transactions:{
                        count: 0,
                        perSecond: 0
                    },
                    height: 0,
                    locked: 0,
                    burned: 0,
                    burnedToday: 0,
                    registrations: 0,
                    registeredToday: 0,
                    events:{
                        count:0,
                        perSecond: 0,
                    },
                    blockTime: 5
                }
            }
        },
        props: {
            lastBlock: {
                type: Number,
                default: 0
            },
        },
        created() {
            this.getStatistic()
        },
        methods:{
            getStatistic() {
                axios({
                    method: "get",
                    url: CHAIN_STATISTIC_CACHE,
                    timeout: 1000 * 20,
                }).then(response => {
                    let info = response.data
                    // this.statistic.mined.current = Number(info?.totalSupply.find(item=>item.denom==='uaxm').amount + 'e-6')
                    this.statistic.mined.current = Math.round(info?.totalSupply / 1000000)
                    this.statistic.mined.total = Math.round(info?.maximalSupply / 1000000)
                    // this.statistic.locked = Number(info?.totalSupply.find(item=>item.denom==='uaxmd').amount + 'e-6')
                    this.statistic.locked = Math.round(info?.delegatedSupply / 1000000)
                    this.statistic.burned = Math.round((info?.burnedTotal || 0) / 1000000)
                    this.statistic.burnedToday = Math.round((info?.burnedToday || 0) / 1000000)
                    this.statistic.registrations = info?.registrations || 0
                    this.statistic.registeredToday = info?.registeredToday || 0
                    this.statistic.events.count = info?.eventsTotal
                    this.statistic.events.perSecond = info?.eventsPerSecond
                    this.statistic.transactions.count = info?.txsTotal
                    this.statistic.transactions.perSecond = info?.txsPerSecond
                    this.statistic.blockTime = 1/info?.blocksPerSecond
                }).catch(error => {
                    if (error.code === 'ECONNABORTED') {
                        console.log('Request timed out');
                    } else {
                        console.log(error.message);
                    }
                });
            },
        }
    }
</script>

<style scoped>

</style>