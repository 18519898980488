<template>
    <div>
        <div class="page-title">
            <div class="backButton" @click="goBack"></div>
            {{ $t('message.validatorDetails') }}
        </div>
        <div class="block-detail-info shadow-block">
            <div class="block-detail-info-header" style="border: none">
                <div class="validator-detail-header">
                    <div class="proposer-icon" :style="'background-image: url(' + validator.icon + ')'"></div>
                    <div style="display: grid;">
                        <div class="subtitle-1">{{ $t('table.Validator') }}</div>
                        <div class="trade-number subvalue-1 inline">
                            <div>{{ validator.name }}</div>
                            <div class="message validator-activity"
                                 :class="validator.isActive ? '': 'message-gradient-negative'">
                                <span v-if="validator.isActive" class="message-gradient">{{
                                        $t('table.Active')
                                    }}</span>
                                <span v-else>{{
                                        $t('table.NotActive')
                                    }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="statistic no-grid one-validator-statistic">
                <div class="statistic-block">
                    <div class="title">
                        {{ $t('table.LockedAXM') }}
                    </div>
                    <div class="value">
                        {{ validator.locked?.toLocaleString() }} <span class="currency">AXM</span>
                    </div>
                </div>

                <div class="statistic-block">
                    <div class="title">
                        {{ $t('table.Uptime') }}
                    </div>
                    <div class="value">
                        {{ validator.uptime ? validator.uptime?.toFixed(2) + '%' : '-' }}
                    </div>
                </div>

                <div class="statistic-block">
                    <div class="title">
                        {{ $t('table.VotingPower') }}
                    </div>
                    <div class="value">
                        {{ validator.votingPower ? validator.votingPower + '%' : '-' }}
                    </div>
                </div>
                <div class="statistic-block">
                    <div class="title">
                        {{ $t('table.delegationFee') }}
                        <PopperTooltip class="light" arrow placement="top">
                            <div class="help-icon"></div>
                            <template #content>
                                <div class="popper-content">{{$t('tooltips.validatorDelegationFee')}}</div>
                            </template>
                        </PopperTooltip>
                    </div>
                    <div class="value">
                        {{ validator.delegationFee }}%
                    </div>
                </div>
                <div class="statistic-block">
                    <div class="title">
                        {{ $t('table.txFee') }}
                        <PopperTooltip class="light" arrow placement="top">
                            <div class="help-icon"></div>
                            <template #content>
                                <div class="popper-content">{{$t('tooltips.validatorTxFee')}}</div>
                            </template>
                        </PopperTooltip>
                    </div>
                    <div class="value">
                        {{ validator.txFee }}%
                    </div>
                </div>
            </div>

            <div class="block-detail-info-body">
                <div class="info-block inline-flex">
                    <div class="subtitle-1 no-min-width" @click="openAddressDetail">{{ $t('table.Address') }}:</div>
                    <div class="subvalue-1 block-height inline" style="padding-left: 10px">
                        <div class="hash" v-if="validator?.userAddress?.length">
                            <router-link :to="{ name: 'Address', params: { id: validator.userAddress }}">
                                {{  shrinkString(validator?.userAddress, 30, 6)  }}
                            </router-link>
                        </div>
                        <copy-button :text="validator.userAddress"></copy-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="info-table-block recently-proposed">
            <div class="proposed-block-head">
                <div class="table-selector">
                    <div class="table-selector-item active">{{ $t('table.ValidatorDelegations') }}</div>
                </div>
            </div>
            <validator-delegations-table :validator="currentValidator"></validator-delegations-table>
        </div>

        <div class="validator-duble-info-block">
            <div class="info-table-block recently-proposed">
                <div class="proposed-block-head">
                    <div class="table-selector">
                        <div class="table-selector-item active" style="padding-right: 0">{{ $t('table.RecentlyProposed') }}</div>
                        <div class="total-proposed blueBorder" style=" white-space: nowrap; height: 16px; font-size: 12px;">
                            {{ validator.totalProposed?.toLocaleString() || 0 }}
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="info-table">
                        <proposed-blocks-table :blocks="cuttedProposedBlocks"></proposed-blocks-table>
                    </div>
                </div>
            </div>
            <div class="block-detail-info uptime-infografic">
                <div class="block-detail-info-header uptime-header">
                    <div class="validator-detail-header uptime-title">
                        {{ $t('table.Uptime') }}
                    </div>
                </div>
                <div class="block-detail-info-body border-bottom">
                    <div class="uptime-infografic-items-container">
                        <div v-for="(block,index) in uptimeBlocks" :key="index"
                             class="uptime-infografic-item-container" v-tooltip="block.status">
                            <PopperTooltip class="dark" arrow :content="'' +block.number" style="margin:0; border:0;">
                                <div v-if="block.status == 0" class="uptime-infografic-item missed"></div>
                                <div v-if="block.status == 1" class="uptime-infografic-item signed"></div>
                                <div v-if="block.status == 2" class="uptime-infografic-item proposed"></div>
                            </PopperTooltip>

                        </div>
                    </div>
                </div>
                <div class="block-detail-info-body ">
                    <div class="uptime-infografic-description">
                        <div class="descr-item">
                            <div class="uptime-infografic-item proposed"></div>
                            {{ $t('table.Proposed') }} ({{ validator.totalProposed || 0 }})
                        </div>
                        <div class="descr-item ">
                            <div class="uptime-infografic-item signed"></div>
                            {{ $t('table.Signed') }} ({{ validator.okBlocksSinceStart || 0 }})
                        </div>
                        <div class="descr-item ">
                            <div class="uptime-infografic-item missed"></div>
                            {{ $t('table.Missed') }} ({{ (validator.blocksSinceStart - validator.okBlocksSinceStart) || 0 }})
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {LATEST_CONSENSUS, LATEST, BLOCKS, VALIDATORS} from "../../../routes/routes"
import ProposedBlocksTable from "@/components/Validators/ProposedBlocksTable";
import ValidatorDelegationsTable from "@/components/Validators/ValidatorDelegationsTable.vue";
import axios from "axios";
import CopyButton from "@/components/Tools/CopyButton";
const {bech32} = require('bech32');

export default {
    name: "OneValidator",
    components: {CopyButton, ValidatorDelegationsTable, ProposedBlocksTable},
    props: {},
    data() {
        return {
            currentValidator: '',
            validator: {},
            lockedNDT: 0,
            lastBlock: 0,
            lastBlocks: 0,
            lastProposedBlocks: 0,
            uptimeBlocks: [],
        }
    },
    computed: {
        cuttedProposedBlocks() {
            if (this.lastProposedBlocks.length) {
                return this.lastProposedBlocks.slice(0, 13)
            }
            return []
        },
    },
    created() {
        this.currentValidator = this.$router.currentRoute._value.params.id;
        this.fetchValidator()
        this.fetchLastBlock()
    },
    methods: {
        fetchLastBlock() {
            axios({
                method: "get",
                url: LATEST,
                timeout: 1000 * 20,
            }).then(async (response) => {
                this.lastBlock = response.data._id
                await this.fetchBlocks()
            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        },
        fetchConsensus() {
            this.uptimeBlocks = []
            axios({
                method: "get",
                url: LATEST_CONSENSUS,
                timeout: 1000 * 20,
            }).then(response => {
                let info = response.data
                this.uptimeBlocks = []
                info.forEach((item) => {
                    let status = 0

                    let foundInConsensus = item.validators.find(element => element.address === this.validator.consensusAddress);
                    if (foundInConsensus) {
                        status = 1
                    }

                    let sined = this.lastProposedBlocks?.find(el => el.height === item._id)
                    if (sined) {
                        status = 2
                    }

                    this.uptimeBlocks.push(
                        {
                            number: item._id,
                            status: status
                        },
                    )
                })
            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        },
        fetchBlocks() {
            axios({
                method: "get",
                url: BLOCKS +
                    (this.lastBlock - 169) + '-' +
                    (this.lastBlock),
                timeout: 1000 * 20,
            }).then(response => {
                let blocksList = response.data
                this.lastBlocks = []
                this.lastProposedBlocks = []
                blocksList.forEach((info) => {
                    let block = {
                        height: info._id,
                        hash: info.hash,
                        proposerAddress: info.proposer,
                        consensusAddress: info.consensusAddress,
                        TxCount: info.txCnt,
                        blockTime: this.$dayjs(info.timestamp).unix(),
                    }
                    this.lastBlocks.reverse()
                    this.lastBlocks.push(block)
                    this.lastBlocks.reverse()

                    if (this.lastProposedBlocks.length < 170) {
                        if (block.proposerAddress == this.validator?.consensusAddress) {
                            this.lastProposedBlocks.reverse()
                            this.lastProposedBlocks.push(block)
                            this.lastProposedBlocks.reverse()
                        }
                    }
                });

                this.fetchConsensus()

            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        },
        fetchValidator() {
            let params = ''

            axios({
                method: "get",
                url: VALIDATORS
                    + params,
                timeout: 1000 * 20,
            }).then(response => {
                let item = response.data.find((elem) => {
                    return (elem.bech32Address === this.currentValidator)
                })

                let fullPower = 0
                for (const item of response.data) {
                    if(item.isActive && item.votingPower){
                        fullPower += Number(item.votingPower)
                    }
                }

                let decode = bech32.decode(item.bech32Address)
                let bits = new Uint32Array(decode.words);
                let prefix = 'axm';
                let address = bech32.encode(prefix, bits)

                this.validator = {
                    name: item.name,
                    address: item.bech32Address,
                    userAddress: address,
                    icon: item.logoUrl,
                    isActive: item.isActive,
                    blocksSinceStart: item.blocksSinceStart,
                    okBlocksSinceStart: item.okBlocksSinceStart,
                    totalProposed: item.totalProposed,
                    consensusAddress: item.consensusAddress,
                    delegationFee: item.delegationFee,
                    txFee: item.txFee,
                    locked: Math.trunc(Number(item.locked + 'e-6')) || 0,
                    votingPower: (item.isActive && item.votingPower) ? ((item.votingPower/fullPower)*100).toFixed(2): 0,
                    uptime: (item.okBlocksSinceStart / item.blocksSinceStart) * 100,
                    webSite: item.siteUrl,
                    securityContact: item.securityContact,
                }
            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        },
        openAddressDetail() {
            this.$emit('changeActiveAddress', this.validator);
        },
    }
}
</script>

<style scoped>

</style>