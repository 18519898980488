<template>
    <div class="block-detail-info address-detail">
        <div class="block-detail-info-header">
            <div class="block-number space-between-container inline" v-if="address.address === 'axm1pyasxzcclpu3dsu36tnchajzqs7q0mq5yvq0g2'
            || address.address === 'axm1534jpvtd8pps0kxtya58mf8u4tq3w2qsg3092w'">
                <div class="title title-flex-between">
                    {{ $t('table.Information') }}
                    <img v-if="address.address === 'axm1pyasxzcclpu3dsu36tnchajzqs7q0mq5yvq0g2'" class="mexc"
                         src="@/assets/icons/mexc.png"/>
                    <img v-if="address.address === 'axm1534jpvtd8pps0kxtya58mf8u4tq3w2qsg3092w'" class="mexc"
                         src="@/assets/icons/biconomy.png"/>
                </div>
            </div>
            <div v-else class="space-between-container inline" style="margin-bottom: 5px">
                <div class="title">{{ $t('table.Information') }}</div>
                    <div class="status_data" v-if="address.premium && address.logoUrl" >
                        <div class="status-icon" style="margin-right: 0; margin-top: -3px"
                             :style="'background-image: url(' + address.logoUrl + ')'"></div>
                    </div>
            </div>
            <div class="info-block space-between-container">
                <div class="subtitle-1 inline">{{ $t('table.Address') }}</div>
                <div class="block-number inline">
                    <div class="subvalue-1 inline">
                        {{ shrinkString(address.address, 25, 6) }}
                        <copy-button :text="address.address"></copy-button>
                    </div>
                </div>
            </div>
            <div class="info-block space-between-container" v-if="address.nickname">
                <div class="subtitle-1 inline">{{ $t('table.Nickname') }}</div>
                <div class="block-number inline">
                    <div class="subvalue-1 inline">
                        <a v-if="address.telegram" :href="'https://t.me/' + address.telegram" target="_blank">
                            <img style="width: 15px; height: 13px; margin-top: 2px; margin-left: 5px; margin-right: 5px" src="@/assets/icons/telegram.png"></a>
                        <span v-if="address.premium && !address.confirmed" class="premium-icon" style="margin-left: 0"></span>
                        <span v-if="address.confirmed" class="confirm-icon"></span>{{ address.nickname }}
                        <copy-button :text="address.nickname"></copy-button>
                    </div>
                </div>
            </div>
            <div class="info-block space-between-container" v-if="address.referrer">
                <div class="subtitle-1 inline">{{ $t('table.Referrer') }}
                    <PopperTooltip class="light" arrow placement="top">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content" style="width: 230px">{{ $t('tooltips.Referrer') }}</div>
                        </template>
                    </PopperTooltip>
                </div>

                <div class="subvalue-1 inline blue-text">
                    <a v-if="address.referrerTelegram" :href="'https://t.me/' + address.referrerTelegram" target="_blank">
                        <img style="width: 15px; height: 13px; margin-top: 2px; margin-left: 5px; margin-right: 5px" src="@/assets/icons/telegram.png"></a>
                    <span v-if="address.referrerPremium && !address.referrerConfirmed" class="premium-icon" style="margin-left: 0"></span>
                    <span v-if="address.referrerConfirmed"
                          class="confirm-icon"></span>
                    <router-link :to="{ name: 'Address', params: { id: address.referrerAddress }}">{{
                        address.referrer
                        }}
                    </router-link>
                </div>
            </div>
            <div style="margin-right: 16px; margin-top: 10px; transition: 0.5s; opacity: 0" :class="timerEnds ? 'show': ''">
                <adv-premium v-if="address.premium && !myPremium"></adv-premium>
            </div>
        </div>
        <div class="block-detail-info-header border-left border-right">
            <div class="block-number space-between-container inline">
                <div class="title">{{ $t('table.Balance') }}</div>
            </div>
            <div class="info-block space-between-container">
                <div class="subtitle-1 inline">{{ $t('table.Total') }}
                    <PopperTooltip class="light" arrow placement="top">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content">{{ $t('tooltips.addressBalanceTotal') }}</div>
                        </template>
                    </PopperTooltip>
                </div>
                <div class="subvalue-1 inline">{{
                    (+((address.UAXM + address.UAXMD).toFixed(1)) || 0).toLocaleString().replace(",", ".")
                    }} AXM
                </div>
            </div>
            <div class="info-block space-between-container">
                <div class="subtitle-1 inline">{{ $t('table.Available') }}
                    <PopperTooltip class="light" arrow placement="top">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content" style="width: 230px">{{ $t('tooltips.addressAvailable') }}</div>
                        </template>
                    </PopperTooltip>
                </div>
                <div class="subvalue-1 inline">{{
                    (+(address.UAXM.toFixed(1)) || 0).toLocaleString().replace(",", ".")
                    }} AXM
                </div>
            </div>
            <div class="info-block space-between-container">
                <div class="subtitle-1 inline">{{ $t('table.Delegated') }}
                    <PopperTooltip class="light" arrow placement="top">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content" style="width: 230px">{{ $t('tooltips.addressDelegated') }}</div>
                        </template>
                    </PopperTooltip>
                </div>
                <div class="subvalue-1 inline">{{
                    (+(address.UAXMD.toFixed(1)) || 0).toLocaleString().replace(",", ".")
                    }} AXM
                </div>
            </div>
            <div class="info-block space-between-container">
                <div class="subtitle-1 inline">{{ $t('table.undelegating') }}</div>
                <div class="subvalue-1 inline">
                    {{ (+(address.unbounding.toFixed(2)) || 0).toLocaleString().replace(",", ".") }} AXM
                </div>
            </div>
            <div class="info-block space-between-container">
                <div class="subtitle-1 inline">{{ $t('table.ClaimableRewards') }}
                    <PopperTooltip class="light" arrow placement="top">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content" style="width: 230px">{{ $t('tooltips.addressClaimable') }}</div>
                        </template>
                    </PopperTooltip>
                </div>
                <div class="subvalue-1 inline">
                    {{ (+(address.ClaimableRewards.toFixed(1)) || 0).toLocaleString().replace(",", ".") }} AXM
                </div>
            </div>

            <div class="info-block space-between-container">
                <div class="subtitle-1 inline">{{ $t('table.enrolledRewards') }}
                    <PopperTooltip class="light" arrow placement="top">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content" style="width: 230px">{{ $t('tooltips.delegation_wallet_get_rewards') }}</div>
                        </template>
                    </PopperTooltip>
                </div>
                <div class="subvalue-1 inline" :class="+(address.enrolledRewards.toFixed(2)) > 0 ? 'positive-text' : ''">
                    {{ +(address.enrolledRewards.toFixed(2)) > 0 ? '+' : '' }}
                    {{
                        (+(address.enrolledRewards.toFixed(2)) || 0).toLocaleString().replace(",", ".")
                    }} AXM
                </div>
            </div>

        </div>
        <div class="block-detail-info-header">
            <div class="space-between-container inline" style="margin-bottom: 5px">
                <div class="title">{{ $t('table.Partnership') }}</div>
                <router-link style="display: block; margin-top: -5px;" :to="{ name: 'Progress', params: { id: address.address }}">
                    <div class="status_data">
                        <div class="status_name blue-text">{{ statusesSamples[address.status]?.name }}</div>
                        <div class="status-icon" style="margin-right: 0"
                             :style="'background-image: url(' + statusesSamples[address.status]?.icon + ')'"></div>
                    </div>
                </router-link>
            </div>
            <div class="info-block space-between-container">
                <div class="subtitle-1 inline">{{ $t('table.TeamSize') }}
                    <PopperTooltip class="light" arrow placement="top"
                                   :content="$t('tooltips.addressTeamSize')">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content" style="width: 230px">{{ $t('tooltips.addressTeamSize') }}</div>
                        </template>
                    </PopperTooltip>
                </div>
                <div class="subvalue-1 inline">{{ (address.teamSize || 0).toLocaleString() }}
                    &nbsp;<span class="subtitle-1 inline padding-r-0">(+{{ (address.teamSizeDaily || 0).toLocaleString() }})</span>
                </div>
            </div>
            <div class="info-block space-between-container">
                <div class="subtitle-1 inline">{{ $t('table.TeamBalance') }}
                    <PopperTooltip class="light" arrow placement="top"
                                   :content="$t('tooltips.addressTeamBalance')">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content" style="width: 230px">{{ $t('tooltips.addressTeamBalance') }}
                            </div>
                        </template>
                    </PopperTooltip>
                </div>
                <div class="subvalue-1 inline">
                    {{ (+address.teamBalance.toFixed(1)).toLocaleString().replace(",", ".") || '-' }} AXM
                </div>
            </div>
            <div class="info-block space-between-container">
                <div class="subtitle-1 inline">{{ $t('table.Teamdelegation') }}
                    <PopperTooltip class="light" arrow placement="top"
                                   :content="$t('tooltips.addressTeamdelegation')">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content" style="width: 230px">
                                {{ $t('tooltips.addressTeamdelegation') }}
                            </div>
                        </template>
                    </PopperTooltip>
                </div>
                <div class="subvalue-1 inline">
                    {{ (+(address.teamDelegated.toFixed(1)) || 0).toLocaleString().replace(",", ".") }} AXM
                </div>
            </div>
            <div class="info-block space-between-container">
                <div class="subtitle-1 inline">{{ $t('table.TeamUnDelegation') }}
                    <PopperTooltip class="light" arrow placement="top"
                                   :content="$t('tooltips.addressTeamUnDelegation')">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content" style="width: 230px">
                                {{ $t('tooltips.addressTeamUnDelegation') }}
                            </div>
                        </template>
                    </PopperTooltip>
                </div>
                <div class="subvalue-1 inline">
                    {{ (+(address?.teamUndelegating?.toFixed(1)) || 0).toLocaleString().replace(",", ".") }} AXM
                </div>
            </div>
            <div class="info-block space-between-container">
                <div class="subtitle-1 inline">{{ $t('table.PartnerRewards') }}
                    <PopperTooltip class="light" arrow placement="top"
                                   :content="$t('tooltips.addressPartnerRewards')">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content" style="width: 230px">
                                {{ $t('tooltips.addressPartnerRewards') }}
                            </div>
                        </template>
                    </PopperTooltip>
                </div>
                <div class="subvalue-1 inline" :class="+(address.partnerRewards.toFixed(1)) > 0 ? 'positive-text' : ''">
                    <span v-if="address.address ==='axm1ytal6q2qc3rkha3x24m4ynh9nvzmpkm8lucq00'"
                          class="burning-icon"></span>
                    {{ +(address.partnerRewards.toFixed(1)) > 0 ? '+' : '' }}
                    {{
                    (+(address.partnerRewards.toFixed(1)) || 0).toLocaleString().replace(",", ".")
                    }} AXM
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {statuses} from "@/mixins/statuses";
import CopyButton from "@/components/Tools/CopyButton";
import AdvPremium from "@/components/Tools/advPremium";

export default {
  name: "AddressStatistic",
  components: {AdvPremium, CopyButton},
  mixins: [statuses],
  data() {
    return {
        myPremium: '',
        timerEnds: false
    }
  },
  props: {
    address: {
      type: Object,
      default: () => ({})
    }
  },
    created() {
        this.myPremium = localStorage.getItem("premium");

        this.bus.on('premiumPropertyUpdated',()=>{
            this.myPremium = localStorage.getItem("premium");
        })
    },
    mounted() {
      setTimeout(()=>{
          this.timerEnds= true
      },500)
    },
}
</script>

<style scoped>
.show{
    opacity: 1 !important;
}
</style>