<template>
    <div class="blockchain-index">
        <global-search></global-search>
        <chain-data :lastBlock="lastBlock"></chain-data>

        <div class="info-table-block">
            <div class="row">
                <div class="info-table">
                    <blocks-table :blocks="blocks.data"></blocks-table>
                </div>
            </div>
            <div class="table-nav">
                <div class="show-all grey-button button" style="visibility: hidden; display: none">
                    {{ $t('message.showAll') }}
                </div>
                <explorer-paginator :current-page="blocks.current_page" :last-page="Math.ceil(lastBlock/pageCount)"
                                    @pagePlusArgument="pagePlusArgument" @prevPage="prevPage"
                                    @nextPage="nextPage" @goLastPage="goLastPage"/>
                <show-lines-count @count="setPageCount"></show-lines-count>
            </div>
        </div>
    </div>
</template>

<script>
import {BLOCKS, LATEST, VALIDATORS} from "../../../routes/routes"
import ChainData from "@/components/Blocks/ChainData";
import ExplorerPaginator from "@/components/Tools/ExplorerPaginator";
import BlocksTable from "@/components/Blocks/BlocksTable";
import ShowLinesCount from "@/components/Filters/ShowLinesCount";
import axios from "axios";
import GlobalSearch from "@/components/Filters/GlobalSearch";

export default {
    name: "BlocksIndex",
    components: {GlobalSearch, ShowLinesCount, BlocksTable, ExplorerPaginator, ChainData},
    data() {
        return {
            lastBlock: 0,
            pageCount: 10,
            validatorsInfo:[],
            firstLoad: true,
            blocks: {
                current_page: 1,
                data: [],
            },
            intervalID: null,
        }
    },
    created() {
        (async () => {
            await  this.fetchValidator()
            await  this.fetchLastBlock()
        })();
    },
    beforeUnmount() {
        clearInterval(this.intervalID);
    },
    mounted() {
    },
    methods: {
        async fetchValidator() {
            return axios({
                method: "get",
                url: VALIDATORS,
                timeout: 1000 * 20,
            }).then(response => {
                response.data.forEach((item)=>{
                    this.validatorsInfo.push({
                        name: item.name,
                        address: item.bech32Address,
                        consensusAddress: item.consensusAddress,
                        icon: item.logoUrl,
                    })
                })
            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        },
        fetchLastBlock() {
            axios({
                method: "get",
                url: LATEST,
                timeout: 1000 * 20,
            }).then(response => {
                let info = response.data
                this.lastBlock = info?._id
                if(this.blocks.current_page === 1 ){
                    let proposer = this.validatorsInfo?.find((elem)=>elem.consensusAddress === info.proposer) ? this.validatorsInfo.find((elem)=>elem.consensusAddress === info.proposer) : {}
                    let block = {
                        number: info?._id,
                        height: info?._id,
                        proposerAddress: info.proposer,
                        proposer: proposer,
                        blockTime: this.$dayjs(info.timestamp).unix(),
                        TxCount: info.txCnt,
                        events: info.evtCnt
                    }
                    let findIndex = this.blocks.data?.findIndex((elem)=>{
                        return elem.number === info?._id
                    })
                    if(findIndex === -1){
                        this.blocks.data.pop()
                        this.blocks.data.reverse()
                        this.blocks.data.push(block)
                        this.blocks.data.reverse()
                    }
                }

                clearInterval(this.intervalID);
                this.intervalID = setInterval(() => {
                    this.fetchLastBlock()
                }, 5000)

                this.firstLoad? this.fetchBlocks(): '';
                this.firstLoad = false;
            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        },
        fetchBlocks() {
            axios({
                method: "get",
                url: BLOCKS +
                    (this.lastBlock - (this.blocks.current_page * this.pageCount) + 1) + '-'
                    + (this.lastBlock  - (((this.blocks.current_page -1) * this.pageCount))),
                timeout: 1000 * 20,
            }).then(response => {
                let blocksList = response.data
                this.blocks.data = []
                blocksList.forEach((info) => {
                    let proposer = this.validatorsInfo?.find((elem)=>elem.consensusAddress === info.proposer) ? this.validatorsInfo.find((elem)=>elem.consensusAddress === info.proposer) : {}
                    let block = {
                        number: info._id,
                        height: info._id,
                        proposerAddress: info.proposer,
                        proposer: proposer,
                        blockTime: this.$dayjs(info.timestamp).unix(),
                        TxCount: info.txCnt,
                        events: info.evtCnt
                    }
                    this.blocks.data.reverse()
                    this.blocks.data.push(block)
                    this.blocks.data.reverse()
                });

            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        },
        setPageCount(e) {
            this.pageCount = e
            this.blocks.current_page = 1
            this.fetchBlocks()
        },
        prevPage() {
            if(this.blocks.current_page > 1){
                this.blocks.current_page = this.blocks.current_page - 1
                this.fetchBlocks()
            }
        },
        nextPage() {
            if(this.blocks.current_page < Math.ceil(this.lastBlock/this.pageCount)) {
                this.blocks.current_page = this.blocks.current_page + 1
                this.fetchBlocks()
            }
        },
        goLastPage() {
            this.blocks.current_page = Math.ceil(this.lastBlock/this.pageCount)
            this.fetchBlocks()
        },
        pagePlusArgument(e) {
            this.blocks.current_page = this.blocks.current_page + e
            this.fetchBlocks()
        },
    }

}
</script>

<style scoped>

</style>